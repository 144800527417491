import ChatModal from './components/ChatModal';

function App() {
	return (
		<div className='App'>
			<ChatModal />
		</div>
	);
}

export default App;
