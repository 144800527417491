// Welcome.js

import React from "react";
import { translations } from "../translations";

const Welcome = ({ selectLanguage = "fr" }) => {
  const lang = translations[selectLanguage];

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <img
        src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/LOGO+(6).png"
        alt="Welcome"
        className="w-26.25 h-16 mb-5"
      />
      <div className="text-left">
        <h1
          style={{
            fontWeight: "bold",
          }}
          className="text-xl font-semibold mb-2 ml-5 mr-5"
        >
          {lang.welcome}
        </h1>
        <p className="text-l ml-5 mr-5">{lang.askQuestions}</p>
        <p className="text-m ml-5 mr-5">{lang.discover}</p>
      </div>
    </div>
  );
};

export default Welcome;
