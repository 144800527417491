import React from "react";
import { translations } from "../translations";

const ChatBoxLoader = ({ selectLanguage }) => {
  return (
    <>
      <img
        src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/loader.gif"
        alt=""
        className=" mx-1 my-2 inline-block w-8 h-8 "
      />
      <span>{translations[selectLanguage].search} ...</span>
    </>
  );
};

export default ChatBoxLoader;
