export const translations = {
  en: {
    user: "You",
    assistant: "Elena",
    agree: "I Agree",
    disagree: "I Disagree",
    agreed: "You have agreed.",
    disagreed: "You have disagreed.",
    welcome: "Hello, I'm Elena, the AI assistant of Meridiem !",
    askQuestions: "Ask me all your questions about Meridiem.",
    discover: "I will answer them with passion!",
    search: "Searching in my sources",
  },
  de: {
    user: "Sie",
    assistant: "Elena",
    agree: "Ich stimme zu",
    disagree: "Ich stimme nicht zu",
    agreed: "Sie haben zugestimmt.",
    disagreed: "Sie haben nicht zugestimmt.",
    welcome: "Hallo, ich bin Elena, die KI-Assistentin von Meridiem!",
    askQuestions: "Stellen Sie mir alle Ihre Fragen zu Meridiem.",
    discover: "Ich werde sie mit Leidenschaft beantworten!",
    search: "Ich suche in meinen Quellen",
  },
  nl: {
    user: "U",
    assistant: "Elena",
    agree: "Ik ga akkoord",
    disagree: "Ik ga niet akkoord",
    agreed: "U bent akkoord gegaan.",
    disagreed: "U bent niet akkoord gegaan.",
    welcome: "Hallo, ik ben Elena, de AI-assistente van Meridiem!",
    askQuestions: "Stel me al je vragen over Meridiem.",
    discover: "Ik zal ze met passie beantwoorden!",
    search: "Aan het zoeken in mijn bronnen",
  },
  fr: {
    user: "Vous",
    assistant: "Elena",
    agree: "Je suis d'accord",
    disagree: "Je ne suis pas d'accord",
    agreed: "Vous avez accepté.",
    disagreed: "Vous n'avez pas accepté.",
    welcome: "Bonjour, je suis Elena, l'assistante IA de Meridiem !",
    askQuestions: "Posez-moi toutes vos questions à propos de Meridiem.",
    discover: "J'y répondrai avec passion !",
    search: "Je cherche dans mes sources",
  },
};
